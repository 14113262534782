import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getFileExtension,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import axios from "../../axios";
import { useSelector } from "react-redux";
import Loader from "../../utils/Loader";
import { FaImage } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../../utils/fileUpload";
const EvidenceModal = ({ modalShow, setShowModal, userData, setIsUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [fileArr, setFileArr] = useState([]);

  // const [selectedFiles, setSelectedFiles] = useState([]);
  // console.log(selectedFiles);

  // const handleFileChange = (event) => {
  //   setSelectedFiles([...selectedFiles, ...event.target.files]);
  // };

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles);
    setFileArr([...fileArr, ...acceptedFiles]);
    // console.log(acceptedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });

  const updateLead = async (e) => {
    setIsLoading(true);
    let fileList = [];

    const url = `/leads/update-lead`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    // const formData = new FormData();
    // formData.append("email", userData.email);
    // formData.append("file", e.target.files[0]);

    for (let i = 0; i < fileArr.length; i++) {
      const ext = getFileExtension(fileArr[i].name);
      const image = await uploadFile(fileArr[i], ext, setIsLoading);
      fileList.push(image);
    }

    const formData = {
      email: userData.email,
      filesArr: fileList,
    };

    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        if (setIsUpdate) {
          setIsUpdate((p) => !p);
        }
        setIsLoading(false);
        setFileArr([]);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };

  return (
    <Modal
      centered
      className="bootstrap-modal-custom comment-modal evidence_modal_container"
      show={modalShow}
      onHide={() => setShowModal(false)}
    >
      {isLoading ? (
        <Loader Height={"40vh"} />
      ) : (
        <Modal.Body>
          <h3>Upload Your evidences</h3>
          <label className="evidence_modal_label">Upload</label>
          <div
            {...getRootProps()}
            className={`dropzone  ${isDragActive ? "active" : ""}`}
          >
            <label className="evidence_modal_file" htmlFor="fileID">
              <p>Browse Your device</p>
              <span>Or</span>
              <span>Drag & Drop here</span>
            </label>
          </div>
          <input
            className="d-none"
            id="fileID"
            type="file"
            multiple
            accept="image/*"
            // onChange={updateLead}
            {...getInputProps()}
          />
          <div className="evidence_modal_preview_image">
            {fileArr.map((file, index) => (
              <div key={index} className="evidence_modal_preview_image_div">
                <FaImage size={30} color="rgba(59, 143, 238, 1)" />
                <p>{file.name}</p>
                <RxCross2
                  onClick={() => {
                    setFileArr(fileArr.filter((_, i) => i !== index));
                  }}
                  size={20}
                />
              </div>
            ))}
          </div>
          <div className="upload_multiple cursor-pointer" onClick={updateLead} >
            Upload Evidence
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default EvidenceModal;
